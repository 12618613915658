<template>
  <el-card class="box-card">
    <el-col :span="12">
      <el-button icon="el-icon-plus" type="warning" @click="handleEdit" size="small">
        新增
      </el-button>
      <el-button icon="el-icon-plus" type="primary" size="small" @click="addExcel">
        导入语言文件
      </el-button>
      <!--      <el-button icon="el-icon-plus" type="primary" @click="handleAdd()" size="small">新增关键字</el-button>-->
    </el-col>
    <el-col :span="12" style="text-align: right">
      缺省语言:
      <el-select
          v-model="form.code"
          clearable
          placeholder="请选择语言"
          style="margin-right: 10px"
          size="small"
          @change="getLanguages"
      >
        <el-option
            v-for="item in languageArr"
            :key="item.code"
            :label="item.name"
            :value="item.code"
        />
      </el-select>
    </el-col>

    <el-dialog
        :close-on-click-modal="false"
        title="导入语言文件"
        :visible.sync="dialogFormVisible"
        width="550px"
        @close="close"
    >
      <el-form ref="fileArr" :model="fileArr" :rules="ruleObj" label-width="120px">
        <el-form-item label="源语言语种" v-model="fileArr.to_code" prop="to_code">
          <el-select
              v-model="fileArr.to_code"
              clearable
              placeholder="请选择语言"
              style="width: 100%"
              size="small"
          >
            <el-option
                v-for="item in languageArr"
                :key="item.code"
                :label="item.name"
                :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="文件" v-model="fileArr.url" prop="url">
          <el-button v-if="!fileArr.url" @click="showSelect = true">选择上传文件</el-button>
          <div v-else>{{ fileArr.url }}</div>
          <fileSelect :visible.sync="showSelect" append-to-body state="file" title="标题"
                      @confirm="confirmHandle"></fileSelect>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>

    <el-table
        v-loading="listLoading"
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="name_cn"
          label="语言">
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="终端语言文字">
      </el-table-column>
      <el-table-column
          align="center"
          prop="code"
          label="语言编码"
      >
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleTranslation(row)">翻译</el-button>
          <el-button type="text" @click="handleBuild(row)">生成代码</el-button>
          <el-button type="text" @click="handleExcel(row)">导出</el-button>
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Edit ref="Edit" @fetch-data="fetchData"/>
    <AddEdit ref="AddEdit"/>
  </el-card>
</template>
<script>
import Edit from './components/languageEdit'
import AddEdit from './components/addEdit'
import fileSelect from "@/views/components/fileSelect";
import {
  apiDeleteLanguageMsg, apiGetLanguageExcel,
  apiSetLanguagesConfig,
  apiGetLanguagesList, apiGetLanguagesConfig, apiImportLanguageFile,apiBuildCode,
} from "@/request/api";

export default {
  name: 'languagelist',
  components: {AddEdit, Edit, fileSelect},
  props: [],
  data() {
    return {
      dialogFormVisible: false,
      languageArr: [],
      listLoading: false,
      showSelect: false,
      queryForm: {
        page: 1,
        pageSize: 10,
      },
      form: {
        code: 'zh-CN',
      },
      fileArr: {
        to_code: '',
        url: '',
      },
      ruleObj: {
        to_code: [
          {required: true, trigger: 'blur', message: '目标语言不能为空'},
        ],
        url: [
          {required: true, trigger: 'blur', message: '语言文件不能为空'},
        ],
      },
      list: [],
      fileList: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
    this.getConfig()
  },
  mounted() {
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    //上传文件
    confirmHandle(e) {
      console.log(e)
      if (e[0].url) {
        this.fileArr.url = e[0].url
      }
      this.showSelect = false
    },
    //导出
    handleExcel(row) {
      apiGetLanguageExcel({code: row.code}).then(res => {
        if (res.code == 200) {
          window.location.href = res.data[0]
        }
      })
    },
    close() {
      this.fileList = []
      this.dialogFormVisible = false
    },
    addExcel() {
      this.dialogFormVisible = true
    },
    // 上传前对文件的大小的判断
    beforeAvatarUpload(file) {
      console.log(file)
      const extension = file.name.split('.')[1] === 'xls'
      const extension2 = file.name.split('.')[1] === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2) {
        this.$message.error('上传模板只能是 xls、xlsx格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传模板大小不能超过 10MB!')
      }
      return extension || extension2
    },
    //上传文件数量限制
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
              files.length + fileList.length
          } 个文件`
      )
    },
    //上传文件提示
    handlePreview(file) {
      console.log(file)
      if (file.response.status) {
        this.$message.success('文件导入成功!')
      } else {
        this.$message.error('此文件导入失败!')
      }
    },
    //上传返回
    uploadSuccess(response, file, fileList) {
      // console.log(response)
      console.log(file)
      console.log(fileList)
      if (response.code == 200) {
        this.$message.success('导入人员成功！')
        this.send_state = false
        // this.sendObj = {}
        this.fileList = []
        this.fetchData()
      } else {
        this.$message.error(response.code_user_msg)
      }
    },
    //跳转翻译
    handleTranslation(row) {
      this.$router.push({
        name: 'translationlist',
        query: {
          code: row.code,
          config: this.form.code,
        }
      })
    },
    //提交选择文件
    submit() {
      this.$refs['fileArr'].validate(async (valid) => {
        if (valid) {
          console.log(this.fileArr)
          apiImportLanguageFile(this.fileArr).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.fileArr = {
                to_code: '',
                url: '',
              }
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    fullPackageSettings() {
      this.$router.push({
        path: 'fullPackageSettings',
      })
    },
    handleAdd(row) {
      this.$refs['AddEdit'].showEdit()
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除多语言
    handleDelete(row) {
      if (row.code != this.form.code) {
        this.$confirm('你确定要删除当前语言吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteLanguageMsg({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('当前语言为缺省语言，无法删除！')
      }
    },
    // 生成代码
      handleBuild(row) {
          apiBuildCode({code: row.code}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
    },
    getConfig() {
      apiGetLanguagesConfig().then(res => {
        if (res.code == 200) {
          this.form.code = res.data[0]
        }
      })
    },
    //设置缺省语言
    getLanguages() {
      apiSetLanguagesConfig(this.form).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    async fetchData() {
      this.listLoading = true
      apiGetLanguagesList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data
          this.languageArr = res.data
          // this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>

