<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="语言内容" prop="keyword">
        <el-input type="textarea" autosize v-model="form.keyword" placeholder="请输入内容" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="语种" prop="code">
        <el-select
            v-model="form.code"
            size="small"
            placeholder="请选择"
            style="width: 100%"
        >
          <el-option
              v-for="item in languageArr"
              :key="item.code"
              :label="item.name"
              :value="item.code"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiAddKeywordList, apiGetLanguagesList} from "@/request/api";

export default {
  name: 'addEdit',
  data() {
    return {
      languageArr: [],
      form: {
        keyword: '',
        code: '',
      },
      rules: {
        keyword: [
          {required: true, trigger: 'blur', message: '内容不能为空'},
        ],
        code: [
          {required: true, trigger: 'blur', message: '语种不能为空'},
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
    this.getLanguages()
  },
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    getLanguages() {
      apiGetLanguagesList().then(res => {
        if (res.code == 200) {
          this.languageArr = res.data
        }
      })
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiAddKeywordList(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.close()
            } else {
              this.$message.error(res.message)
              this.close()
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
