<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="语言" prop="name" placeholder="请输入语言">
        <el-input v-model="form.name" size="small" placeholder="请输入语言"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="语言(中文)" prop="name" placeholder="请输入语言">
        <el-input v-model="form.name_cn" size="small" placeholder="请输入语言"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="语言编码" prop="code">
        <el-input v-model="form.code" size="small" placeholder="请输入国家编码"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiAddLanguages} from "@/request/api";

export default {
  name: 'languageEdit',
  data() {
    return {
      coustate: true,
      query_time: [],
      apiRegion: [],
      form: {
        name: '',
        name_cn: '',
        coding: '',
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '语言名不能为空'},
        ],
        code: [
          {required: true, trigger: 'blur', message: '语言编码不能为空'},
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        const tempObj = Object.assign({}, row)
        this.form = {
          id: tempObj.id,
          code: tempObj.code,
          name: tempObj.name,
        }
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiAddLanguages(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message.error(message)
              this.close()
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
